<template>
  <Section class="bg-neutral-150">
    <div class="grid lg:grid-cols-2 md:px-32 xl:px-56 mb-20">
      <header class="text-3xl md:text-4xl lg:text-5.5xl font-semibold mb-8">
        {{ features.title }}
      </header>
      <p class="text-lg lg:text-xl">
        {{ features.description }}
      </p>
    </div>
    <h2 class="text-3xl md:text-4xl lg:text-5xl font-semibold text-center mb-10">
      {{ features.topic }}
    </h2>
    <div class="flex flex-col md:px-32 xl:px-56">
      <MDC
        :value="features.answer"
        tag="article"
        class="text-base bg-white w-full lg:text-lg px-7 py-5 prose max-w-none"
      />
    </div>
  </Section>
</template>

<script setup lang="ts">
 interface Props {
    data: {
      [key: string]: any
    }
  }

  const props = defineProps<Props>()
  const features = toRef(props.data)
</script>
