<template>
  <Section class="bg-white">
    <template #header>{{ prices.title }}</template>
    <div class="md:px-32 xl:px-56">
      <article class="relative group max-w-md mx-auto">
        <NuxtLink
          :to="prices.link"
          class="absolute w-full h-full z-10"
        />
        <div class="h-full flex items-center justify-center bg-white rounded-xl group-hover:drop-shadow-xl py-10">
          <span class="text-4xl font-bold text-primary">{{ prices.value }}</span>/
          <span class="text-4xl font-light">{{ prices.duration }}</span>
        </div>
      </article>
    </div>
    <div class=" flex flex-col gap-y-8 mt-16">
      <div v-if="prices.description" class="mx-auto max-w-xl">
        <p class="text-base text-center">
          {{ prices.description }}
        </p>
      </div>
      <div class="w-full text-center">
        <UButton
          :to="prices.link"
          variant="solid"
          target="_blank"
          size="xl"
        >
          {{ prices.action }}
        </UButton>
      </div>
    </div>
  </Section>
</template>

<script setup lang="ts">
  interface Props {
    data: {
      [key: string]: any
    }
  }

  const props = defineProps<Props>()
  const prices = toRef(props.data)
</script>
